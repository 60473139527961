/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Media, Container, FormGroup, FormFeedback, Button, Input, Label,
} from 'reactstrap';
import WOW from 'wowjs';
import LazyLoad from 'react-lazyload';
import { Field, Formik, Form } from 'formik';
import arrow from 'images/arrow_transparent.svg';
import logo from 'images/Logo_blanco.svg';
import * as yup from 'yup';
import { postInsuranceContact } from '../utils/Api';

const SignupSchema = yup.object().shape({
  name: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .max(50, 'Debe contener menos de 50 caracteres.')
    .required('Requerido *'),
  email: yup.string()
    .email('Correo electrónico inválido.')
    .required('Requerido *'),
  city: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .required('Requerido *'),
  age: yup.number()
    .integer()
    .min(0)
    .required('Requerido *'),
  brand: yup.string()
    .required('Requerido *'),
  model: yup.string()
    .required('Requerido *'),
  year: yup.number()
    .integer()
    .min(0)
    .required('Requerido *'),
  value: yup.string()
    .required('Requerido *'),
});

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

class InsuranceForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      msg: '',
      isSubmitting: false,
    };
  }

  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  render() {
    const {
      color,
    } = this.props;

    return (
      // <LazyLoad once offset={50}>
        <Container>
          <Row className="py-5">
            <Col xs={12}>
              <Media tag="h2" className="pt-0 mb-0 wow bounceInLeft mt-4 mt-md-0 justify-content-center">
                <strong className="mr-2">HOLA!</strong>
              </Media>
              <Media tag="h3" className="mb-0 wow bounceInLeft mt-2 justify-content-center">
                Cotizá tu seguro completando el formulario detallado a continuación:
              </Media>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Formik
                initialValues={{
                  email: '',
                  name: '',
                  city: '',
                  phone: '',
                  section_page: '',
                  age: '',
                  brand: '',
                  model: '',
                  year: '',
                  value: '',
                }}
                validationSchema={SignupSchema}
                onSubmit={(values, actions) => {
                  values.section_page = window.location.pathname;
                  this.setSubmitting(false);
                  postInsuranceContact(values).then((response) => {
                    this.setSubmitting(true);
                    if (typeof response.data === 'string') {
                      this.setState({ msg: response.data });
                    } else {
                      this.setState({ msg: 'Su mensaje a sido enviado exitosamente!!' });
                    }
                    actions.resetForm({ values: '' });
                  }).catch((error) => {
                    this.setState({ msg: error.data });
                  });
                }}
              >
                {(props) => (
                  <span>
                    {this.state.msg && (
                      // <Media tag="h6" className="alert alert-success mt-2" id="msg_insurance_form">
                      //   {this.state.msg}
                      // </Media>
                      <Media tag="h6" className="alert alert-success mt-2" id="consulta_seguros">
                         {this.state.msg}
                      </Media>
                    )}
                    <Form className="mt-5" onSubmit={props.handleSubmit} action="/contact">
                      <Row>
                        <Col xs={12} md={6} className="pr-md-4">
                          <FormGroup>
                            <div
                              className="enter align-items-center mb-5 w-100 border-0 pl-0"
                              style={{
                                backgroundColor: color, boxShadow: 'none', outilne: 0, borderRadius: 0, paddingRight: '50px',
                              }}
                            >
                              <Media tag="h3" className="my-0 mx-0 align-items-center justify-content-center h-100 text-dark">DATOS PERSONALES</Media>
                              <div className="square" style={{ right: 0, left: 'auto', transform: 'rotate(90deg)' }}>
                                <Media src={arrow} className="w-100" width="100" height="100" />
                              </div>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Field name="name" type="text" component={customInputForm} placeholder="NOMBRE *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field name="age" type="text" component={customInputForm} placeholder="EDAD *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field type="email" name="email" component={customInputForm} placeholder="CORREO ELECTRONICO *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field type="text" name="phone" component={customInputForm} placeholder="NUMERO DE TELEFONO" className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field type="text" name="city" component={customInputForm} placeholder="CIUDAD/PROVINCIA *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Media src={logo} className="w-75 mb-5" width="100" height="100" />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6} className="pl-md-4">
                          <FormGroup>
                            <div
                              className="enter align-items-center mb-5 w-100 border-0 pl-0"
                              style={{
                                backgroundColor: color, boxShadow: 'none', outilne: 0, borderRadius: 0, paddingRight: '50px',
                              }}
                            >
                              <Media tag="h3" className="my-0 mx-0 align-items-center justify-content-center h-100 text-dark">DATOS DEL VEHÍCULO</Media>
                              <div className="square" style={{ right: 0, left: 'auto', transform: 'rotate(90deg)' }}>
                                <Media src={arrow} className="w-100" width="100" height="100" />
                              </div>
                            </div>
                          </FormGroup>
                          <FormGroup>
                            <Field name="brand" type="text" component={customInputForm} placeholder="MARCA *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field name="model" type="text" component={customInputForm} placeholder="MODELO *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field type="text" name="year" component={customInputForm} placeholder="AÑO *" required className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Field type="text" name="value" component={customInputForm} placeholder="VALOR *" className="input-form" />
                          </FormGroup>
                          <FormGroup>
                            <Label className="mt-4">VEHÍCULO 0KM?</Label>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              <Field type="radio" name="radio_insurance_0km" className="radio" value="Si" />
                              <span>{' '}</span>
                            </Label>
                            Si
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              <Field type="radio" name="radio_insurance_0km" className="radio" value="No" />
                              <span>{' '}</span>
                            </Label>
                            No
                          </FormGroup>
                          <FormGroup>
                            <Label className="mt-4 text-uppercase">Conocés ̣"Seguro Sobre Ruedas"</Label>
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              <Field type="radio" name="radio_insurance" className="radio" value="Sí, es mi seguro. Quiero volver a cotizar." />
                              <span>{' '}</span>
                            </Label>
                            Sí, es mi seguro. Quiero volver a cotizar.
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              <Field type="radio" name="radio_insurance" className="radio" value="Sí, pero no es mi actual seguro" />
                              <span>{' '}</span>
                            </Label>
                            Sí, pero no es mi actual seguro
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              <Field type="radio" name="radio_insurance" className="radio" value="No" />
                              <span>{' '}</span>
                            </Label>
                            No
                          </FormGroup>
                        </Col>
                        <Col xs={12} sm={{ size: 8, offset: 1 }} md={{ size: 6, offset: 3 }}>
                          <FormGroup>
                            <Button
                              className="enter align-items-center my-5 w-100 border-0 "
                              style={{
                                backgroundColor: color, boxShadow: 'none', outilne: 0, borderRadius: 0,
                              }}
                            >
                              <div className="square">
                                <Media src={arrow} className="w-100" width="100" height="100" />
                              </div>
                              <Media tag="h3" className="my-0 mx-0 align-items-center justify-content-center h-100 text-dark">ENVIAR</Media>
                            </Button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </span>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      // </LazyLoad>
    );
  }
}

InsuranceForm.propTypes = {
  color: PropTypes.string.isRequired,
};

InsuranceForm.defaultProps = {
};

export default InsuranceForm;
