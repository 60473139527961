import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export function postContact(values) {
  return axios({
    method: 'post',
    url: '/contact',
    data: values,
  });
}

export function postProductContact(values) {
  return axios({
    method: 'post',
    url: '/product_contact',
    data: values,
  });
}

export function postInsuranceContact(values) {
  return axios({
    method: 'post',
    url: '/insurance_contact',
    data: values,
  });
}
