/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, Media, Navbar, Collapse, Container,
} from 'reactstrap';
import WspMenu from './WspMenu';
import logo from 'images/agrobikes.svg';
import logoSM from 'images/agrobikes-iso.svg';
import wp from 'images/whatsapp.svg';
import menu from 'images/menu.svg';
import arrow from 'images/arrow-w.svg';
import { Link } from 'react-scroll';
import "../../assets/styles.css"

const refreshPage = () => {
  location.href = "/";  
}

const HeaderBrand = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { brandSlug, logoBrand } = props;

  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <Navbar expand="lg" className="w-100 px-0 py-0">
      <Container className="bg-dark-primary py-2 px-3 items" fluid="lg">
        <NavbarBrand /* href="/" */ onClick={refreshPage} className="py-0">
          <Media className="d-md-inline-block d-none w-100 logo-menu" src={logo} alt="Agrobikes" width="100" height="100" />
          <Media className="d-inline-block d-md-none w-100 logo-menu" src={logoSM} alt="Agrobikes" width="100" height="100" />
        </NavbarBrand>

        <NavbarBrand href={`/${brandSlug}`} className="py-0">
          <Media className="w-75 logo-menu" src={logoBrand} alt={brandSlug} width="100" height="100" />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} className="order-3 hamburguer">
          <Media src={menu} alt="Agrobikes" className="menu-img" width="100" height="100" />
        </NavbarToggler>

        <Collapse isOpen={isOpen} navbar className="order-4 order-lg-0">
          <Nav className="ml-auto text-center" navbar>
            <ItemHeader redirectTo="/" title="INICIO" close={close} />
            <ItemHeader redirectTo={`/${brandSlug}/compania`} title="COMPAÑÍA" close={close} />
            <ItemHeader to="form_about" title="CONTACTO" close={close} />
            <ItemHeader redirectTo="/" title={`SALIR DE ${brandSlug}`} hideDots close={close} />
          </Nav>
        </Collapse>

        {/*
        <NavLink className="order-lg-2 ml-auto" href="https://api.whatsapp.com/send?phone=1125084777" target="_blank" rel="noopener">
          <Media src={wp} alt="Agrobikes" className="menu-img" width="100" height="100" />
        </NavLink>
        */}
        <WspMenu />
      </Container>
    </Navbar>

  );
};

const ItemHeader = (props) => {
  const {
    title, hideDots, to, close, redirectTo,
  } = props;
  return (
    <NavItem className="pr-2 text-nowrap w-100 item-navbar">
      {to && (
      <Link
        className="d-inline-block w-100 nav-link position-relative pr-0"
        to={to}
        spy
        smooth
        offset={-70}
        duration={1500}
        onClick={close}
        isDynamic
      >
        {title}
        {/* <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" /> */}
      </Link>
      )}
      {redirectTo && (
        <NavLink
          className="d-inline-block w-100 nav-link position-relative text-uppercase pr-1"
          href={redirectTo}
        >
          {title}
          {/* <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" /> */}
        </NavLink>
      )}
      { !hideDots
        ? (
          <span className="navbar-separator d-lg-inline-block d-none">
            <span />
            <span />
            <span />
          </span>
        )
        : ''}
    </NavItem>
  );
};

ItemHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hideDots: PropTypes.bool,
  to: PropTypes.string,
  redirectTo: PropTypes.string,
  close: PropTypes.func,
};

ItemHeader.defaultProps = {
  hideDots: false,
  to: '',
  redirectTo: '',
  close: null,
};

HeaderBrand.propTypes = {
  brandSlug: PropTypes.string,
  logoBrand: PropTypes.string,
};

HeaderBrand.defaultProps = {
  brandSlug: '',
  logoBrand: '',
};

export default HeaderBrand;
