import React, { useState } from 'react';
import { NavbarToggler, Media, Collapse } from 'reactstrap';
import wp from 'images/whatsapp.svg';

const WspMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return <>
    <NavbarToggler onClick={toggle} className="rig-pos rig-pos-brand order-5 wsp-toggle">
      <Media src={wp} alt="Agrobikes" className="menu-img" width="100" height="100" />
    </NavbarToggler>
    
    <Collapse isOpen={isOpen} className="wsp-menu order-6 order-lg-0">
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491125106222" target="_blank" rel="noopener">BENELLI</a>
      </li>
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491168463887" target="_blank" rel="noopener">BENELLI</a>
      </li>
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491125090999" target="_blank" rel="noopener">SUZUKI</a>
      </li>
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491125084777" target="_blank" rel="noopener">TVS</a>
      </li>
    </Collapse>
  </>
}

export default WspMenu
