import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormFeedback, FormGroup, Input, Media, Row,
} from 'reactstrap';
import * as yup from 'yup';
import { Field, Formik, Form } from 'formik';
// eslint-disable-next-line import/no-unresolved
import arrow from 'images/arrow_transparent.svg';
import { postProductContact } from '../utils/Api';

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

const SignupSchema = yup.object().shape({
  name: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .max(50, 'Debe contener menos de 50 caracteres.')
    .required('Requerido *'),
  message: yup.string()
    .min(10, 'Debe contener más de 10 caracteres.')
    .max(250, 'Debe contener menos de 250 caracteres.')
    .required('Requerido *'),
  email: yup.string()
    .email('Correo electrónico inválido.')
    .required('Requerido *'),
  city: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .required('Requerido *'),
});

class ProductForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      msg: '',
      isSubmitting: false,
    };
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  render() {
    const {
      brand, productName,
    } = this.props;

    return (
      <Formik
        initialValues={{
          email: '',
          name: '',
          message: '',
          city: '',
          phone: '',
          brand_name: brand,
          product_name: productName,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          this.setSubmitting(false);
          postProductContact(values).then((response) => {
            this.setSubmitting(true);
            if (typeof response.data === 'string') {
              this.setState({ msg: response.data });
            } else {
              this.setState({ msg: 'Su mensaje a sido enviado exitosamente!!' });
            }
            actions.resetForm({ values: '' });
          }).catch((error) => {
            this.setState({ msg: error.data });
          });
        }}
      >
        {(props) => (
          <span>
            {this.state.msg && (
              // <Media tag="h6" className="alert alert-success mt-2">
              //   {this.state.msg}
              // </Media>
              <Media tag="h6" className="alert alert-success mt-2" id="consulta_moto">
                 {this.state.msg}
              </Media>
            )}
            <Form className="mt-5 form-product" data-type="product" onSubmit={props.handleSubmit} action="/product_contact">
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field name="name" type="text" component={customInputForm} placeholder="NOMBRE *" required className="input-form" />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field type="email" name="email" component={customInputForm} placeholder="CORREO ELECTRONICO *" required className="input-form" />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field type="text" name="phone" component={customInputForm} placeholder="NUMERO DE TELEFONO" className="input-form" />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Field type="text" name="city" component={customInputForm} placeholder="CIUDAD/PROVINCIA *" required className="input-form" />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Field type="textarea" name="message" component={customInputForm} placeholder="MENSAJE *" required className="input-form" />
              </FormGroup>
              <Row>
                <Col
                  xs={{ size: 8, offset: 2 }}
                  md={{ size: 6, offset: 6 }}
                  lg={{ size: 4, offset: 8 }}
                >
                  <FormGroup>
                    <Button className="enter dark align-items-center mt-4 w-100 border-0 ">
                      <div className="square">
                        <Media src={arrow} className="w-100" width="100" height="100" />
                      </div>
                      <Media tag="h3" className="my-0 mx-0 align-items-center justify-content-center h-100">ENVIAR</Media>
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </span>
        )}
      </Formik>
    );
  }
}

ProductForm.propTypes = {
  brand: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};

export default ProductForm;
