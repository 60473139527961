import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Media, Collapse, ListGroupItem, ListGroup, Row, Col,
} from 'reactstrap';
// eslint-disable-next-line import/no-unresolved
import arrow from 'images/flecha.svg';
import createMarkup from './Utils';

const DataSpecs = (props) => {
  const [collapse, setCollapse] = useState('engine');

  const {
    description, item, itemName, title,
  } = props;

  const toggle = (e) => {
    const { event } = e.target.dataset;
    setCollapse(collapse === event ? '' : event);
  };

  return (
    <div>
      <Media
        tag="h2"
        className={`subtitle justify-content-center position-relative font-weight-black pb-3 pt-4 ${(collapse === itemName) ? 'collapse-open' : ''}`}
        onClick={toggle}
        data-event={itemName}
      >
        {title}
        <Media
          src={arrow}
          className="collapse-arrow"
          alt=""
          onClick={toggle}
          data-event={itemName}
          width="100"
          height="100"
        />
      </Media>
      <Collapse isOpen={collapse === itemName} className="py-3 description">
        {description && <span dangerouslySetInnerHTML={createMarkup(description)} className="description-html" /> }
        {item
          && (
            <div>
              {item.picture.image_url
            && (
            <div className="position-relative">
              <Media src={item.picture.image_url} alt="" className="w-50 mx-auto" width="100" height="100" />
              <div className="dimension-box">
                <div className="outerline">
                  <div className="innerline" />
                </div>
                <span>
                  <strong>C</strong>
                </span>
                <div className="vouterline">
                  <div className="vinnerline" />
                  <span>
                    <strong className="mr-4">A</strong>
                  </span>
                </div>
                <div className="vouterline left">
                  <div className="vinnerline" />
                  <span>
                    <strong className="mr-4">B</strong>
                  </span>
                </div>
              </div>
            </div>
            )}
              {(item.height_exluding_mirrors
            || item.seat_height || item.lenght)
            && (
            <ListGroup className="text-center mb-3">
              {item.height_exluding_mirrors
                && (
                <ListGroupItem className="border-0 w-75 text-left mx-auto py-0">
                  <Row>
                    <Col xs={2} className="font-weight-black justify-content-center d-flex align-items-center">A</Col>
                    <Col xs={10}>
                      <strong className="text-uppercase">Altura con exclusión de los espejos</strong>
                      {item.height_exluding_mirrors}
                    </Col>
                  </Row>
                </ListGroupItem>
                )}
              {item.seat_height
                && (
                <ListGroupItem className="border-0 w-75 text-left mx-auto py-0">
                  <Row>
                    <Col xs={2} className="font-weight-black justify-content-center d-flex align-items-center">B</Col>
                    <Col xs={10}>
                      <strong className="text-uppercase">altura de asiento</strong>
                      {item.seat_height}
                    </Col>
                  </Row>
                </ListGroupItem>
                )}
              {item.lenght
                && (
                <ListGroupItem className="border-0 w-75 text-left mx-auto py-0">
                  <Row>
                    <Col xs={2} className="font-weight-black justify-content-center d-flex align-items-center">C</Col>
                    <Col xs={10}>
                      <strong className="text-uppercase">longitud</strong>
                      {item.lenght}
                    </Col>
                  </Row>
                </ListGroupItem>
                )}
            </ListGroup>
            )}
              {item.description
            && (
            <span dangerouslySetInnerHTML={createMarkup(item.description)} className="description-html" />
            )}
            </div>
          )}
      </Collapse>
    </div>
  );
};

DataSpecs.propTypes = {
  description: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  itemName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

DataSpecs.defaultProps = {
  description: '',
  item: undefined,
};

export default DataSpecs;
