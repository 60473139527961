/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Nav, NavItem, NavLink, NavbarBrand, NavbarToggler, Media, Navbar, Collapse, Container,
} from 'reactstrap';
import logo from 'images/agrobikes.svg';
import logoSM from 'images/agrobikes-iso.svg';
import wp from 'images/whatsapp.svg';
import menu from 'images/menu.svg';
import arrow from 'images/arrow-w.svg';
import { Link } from 'react-scroll';
import "../../assets/styles.css"

const refreshPage = () => {
  location.href = "/";  
}

const Header = (props) => {
  const { mainBrands, brands,seguros } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <Navbar expand="lg" className="w-100 px-0 py-0">
      <Container className="bg-dark-primary py-2 px-3" fluid="lg">
        <NavbarBrand /*href="/"*/ onClick={refreshPage} className="py-0">
          <Media className="d-md-inline-block d-none w-100" src={logo} alt="Agrobikes" width="100" height="100" />
          <Media className="d-inline-block d-md-none w-100" src={logoSM} alt="Agrobikes" width="100" height="100" />
        </NavbarBrand>

        <NavbarToggler onClick={toggle} className="mid-pos order-3">
          <Media src={menu} alt="Agrobikes" className="menu-img" width="100" height="100" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="order-4 order-lg-0">
          <Nav className="ml-auto text-center" navbar>
            {
              seguros && seguros.length > 0 && <ItemHeader redirectTo="\" title="INICIO" close={close} /> 
              || <ItemHeader to="header" title="INICIO" close={close} />
            }
            {mainBrands
              && mainBrands.map((item) => (
                <ItemHeader redirectTo={`/${item[0]}`} title={item[1]} close={close} key={item[0]} />
              ))
            }
            {brands && brands.length > 0 && <ItemHeader title="OTRAS MARCAS" brands={brands} />}
            <ItemHeader to="service" title="SERVICIO" close={close} />
            <ItemHeader to="insurance" title="SEGUROS" close={close} />
            <ItemHeader to="accessory" title="ACCESORIOS" close={close} />
            <ItemHeader to="form_about" title="CONTACTO" hideDots close={close} />
          </Nav>
        </Collapse>

        <WspMenu />
      </Container>
    </Navbar>

  );
};

const WspMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return <>
    <NavbarToggler onClick={toggle} className="rig-pos order-5 wsp-toggle">
      <Media src={wp} alt="Agrobikes" className="menu-img" width="100" height="100" />
    </NavbarToggler>
    
    <Collapse isOpen={isOpen} className="wsp-menu order-6 order-lg-0">
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491125106222" target="_blank" rel="noopener">BENELLI</a>
      </li>
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491168463887" target="_blank" rel="noopener">BENELLI</a>
      </li>
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491125090999" target="_blank" rel="noopener">SUZUKI</a>
      </li>
      <li className='wsp-row'>
        <Media src={wp} className="" width="25" height="25" />
        <a href="https://api.whatsapp.com/send?phone=5491125084777" target="_blank" rel="noopener">TVS</a>
      </li>
    </Collapse>
  </>
}

const ItemHeader = (props) => {
  const {
    title, hideDots, to, close, redirectTo, brands,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavItem className="pl-2 text-nowrap w-100 item-navbar">
      {to && (
      <Link
        className="d-inline-block w-100 nav-link position-relative"
        to={to}
        spy
        smooth
        offset={-70}
        duration={1500}
        onClick={close}
        isDynamic
      >
        {title}
        {/* <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" /> */}
      </Link>
      )}
      {redirectTo && (
      <NavLink
        className="d-inline-block w-100 nav-link position-relative text-uppercase pr-1"
        href={redirectTo}
      >
        {title}
        {/* <Media src={arrow} className="d-lg-none d-inline-block position-absolute" width="100" height="100" /> */}
      </NavLink>
      )}
      {brands && brands.length > 0 && (
        <div>
          <NavbarToggler onClick={toggle} className="text-white nav-link m-auto position-relative w-100">
            {title }
            {/* <Media src={arrow} className="d-lg-none d-inline-block position-absolute " width="100" height="100" /> */}
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="other-brands">
            <Nav className="mt-2 ml-auto text-center" navbar>
              {brands.map((item) => (
                <NavLink
                  key={item[0]}
                  className="d-inline-block w-100 nav-link position-relative text-uppercase pr-1 py-3"
                  href={item[0]}
                >
                  {item[1]}
                </NavLink>
              ))}
            </Nav>
          </Collapse>
        </div>
      )}
      { !hideDots
        ? (
          <span className="navbar-separator d-lg-inline-block d-none">
            <span />
            <span />
            <span />
          </span>
        )
        : ''}
    </NavItem>
  );
};

Header.prototype = {
  mainBrands: PropTypes.array,
  brands: PropTypes.array,
};

Header.defaultProps = {
  mainBrands: [],
  brands: [],
};

ItemHeader.propTypes = {
  title: PropTypes.string.isRequired,
  hideDots: PropTypes.bool,
  to: PropTypes.string,
  redirectTo: PropTypes.string,
  close: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  brands: PropTypes.array,
};

ItemHeader.defaultProps = {
  hideDots: false,
  to: '',
  redirectTo: '',
  close: null,
  brands: [],
};

export default Header;
