import React,{ Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Media, NavLink, Row,
} from 'reactstrap';
import Slider from 'react-slick';
import WOW from 'wowjs';
import LazyLoad from 'react-lazyload';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
// eslint-disable-next-line import/no-unresolved
import arrow from 'images/arrow_transparent.svg';
import createMarkup from './Utils';
import GalleryImages from './GalleryImages';
import DataSpecs from './DataSpecs';
import ProductForm from './ProductForm';
import BannerCarousel from './BannerCarousel';
import Accessories from './Accessories';

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { downloadOpen: false };
  }

  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  toggle() {
    this.setState((prevState) => ({
      downloadOpen: !prevState.downloadOpen,
    }));
  }

  render() {
    const {
      // eslint-disable-next-line camelcase
      brandName, color, category, name, title, description, maxTorque, note,
      pictureColors, productFeatures, pictures, videos, documents,
      engine, chassis, dimension, accessories,
    } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      fade: true,
      center: true,
      speed: 500,
      lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: 'custom-dots slick-dots',
      customPaging(i) {
        return (
          <button style={{ backgroundColor: pictureColors[i].color }} className="px-1 py-1" />
        );
      },
    };

    return (
      <div className="bg-white text-black product">
        <div className="py-2 w-100 text-white" style={{ backgroundColor: color }}>
          <Container>
            <Media tag="h4" className="ml-1 mb-0 font-weight-bold">{category}</Media>
          </Container>
        </div>

        <Container>
          <Media tag="h1" className="mt-3 mb-0 product-name wow fadeInUp">{name}</Media>
          <Slider {...settings} className="mx-auto mb-5 w-100">
            {pictureColors.map((picture) => (
              <div className="img-container">
                <Media 
                  key={picture.picture.image_url}
                  src={picture.picture.image_url || ''}
                  object
                  alt={name}
                  // className="w-100"
                  // width="100"
                  className="img-moto-color"
                />

                <style>{`
                  .img-container {
                    display: block !important;
                    max-width: calc(100vh + 82px) !important;
                    margin-inline: auto !important;
                  }
                  .img-moto-color {
                    width: 100%;
                  }
                `}</style>
              </div>
            ))}
          </Slider>

          {/* <LazyLoad once offset={30}> */}
            <Media tag="h1" className="my-4 pt-5 wow font-weight-bold fadeInUp">{title}</Media>
            <Media tag="p">
              <span dangerouslySetInnerHTML={createMarkup(description)} className="description-html" />
            </Media>
            <Media tag="p">
              <span dangerouslySetInnerHTML={createMarkup(maxTorque)} className="description-html features w-100" />
            </Media>
            <Row className="pb-5">
              <Col xs={12} md={6}>
                {documents
                  && (
                    <Dropdown
                      isOpen={this.state.downloadOpen}
                      toggle={(this.toggle)}
                      className="d-inline-flex download"
                    >
                      <DropdownToggle style={{ backgroundColor: color }} className="rounded-0 d-flex justify-content-between align-items-center px-1 py-1">
                        <div className="mx-3 font-weight-bold">DESCARGAR</div>
                        <div className="bg-white" style={{ width: '41px', height: '41px' }}>
                          <Media src={arrow} style={{ backgroundColor: color }} className="arrow w-auto" alt={brandName} width="100" height="100" />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        {documents.map((document) => (
                          <DropdownItem key={document.document_url} href={document.document_url} target="_blank">{document.title}</DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  )}
              </Col>
              <Col xs={12} md={6} className="pl-md-0">
                <div className="d-inline-flex mt-3 mt-md-0" style={{ backgroundColor: color }}>
                  <FacebookShareButton
                    url={window.location.href}
                    quote={title}
                    className="ml-3 my-1"
                  >
                    <Media src="/assets/facebook.svg" style={{ width: '38px', height: '38px' }} alt={brandName} width="100" height="100" />
                  </FacebookShareButton>
                  <LinkedinShareButton
                    url={window.location.href}
                    title={title}
                    className="mx-2 my-1"
                  >
                    <Media src="/assets/linkedin.svg" className="ml-2" style={{ width: '38px', height: '38px' }} alt={brandName} width="100" height="100" />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={title}
                    className="mr-2 ml-1 my-1"
                  >
                    <Media src="/assets/twitter.svg" className="" style={{ width: '38px', height: '38px' }} alt={brandName} width="100" height="100" />
                  </TwitterShareButton>
                  <div className="bg-white" style={{ width: '50px' }}>
                    <Media src="/assets/compartir-calado.svg" className="mr-2 w-100" alt={brandName} style={{ backgroundColor: color }} width="100" height="100" />
                  </div>
                </div>
              </Col>
            </Row>
            {productFeatures.length > 0 && <Media tag="h1" className="my-5 wow fadeInUp d-block text-center">Caracteríticas Principales</Media>}
          {/* </LazyLoad> */}
        </Container>

        {/* <LazyLoad once offset={30}> */}
          <Row className="main-features mx-0 justify-content-center">
            {productFeatures.map((feature) => (
              <Col xs={12} sm={6} md={4} lg={3} key={feature.title} className="main-feature px-0 pb-3">
                <Media object src={feature.picture.image_url || ''} alt={feature.title} className="w-100" width="100" height="100" />
                <Media tag="h5" className="text-uppercase font-weight-black my-2 px-4 pb-3 pt-4">{feature.title}</Media>
                <div dangerouslySetInnerHTML={createMarkup(feature.description)} className="description-html px-4 pt-0 pb-4" />
              </Col>
            ))}
          </Row>
        {/* </LazyLoad> */}
        {accessories.length > 0
          && (
          // <LazyLoad once offset={30}>
          <Fragment>
            <Media tag="h1" className="my-5 wow fadeInUp d-block text-center">Accesorios Originales</Media>
            <Accessories items={accessories} />
          </Fragment>
          // </LazyLoad>
          )}
        {/* <LazyLoad once offset={30}> */}
          {pictures.length > 0
          && (
          <div>
            <Media tag="h1" className="py-5 mb-0 wow fadeInUp bg-black text-white d-block text-center">Galería de Imágenes</Media>
            <GalleryImages items={pictures} />
          </div>
          )}
        {/* </LazyLoad> */}
        {/* <LazyLoad once offset={30}> */}
          {videos.length > 0
          && (
            <div className=" bg-black pb-5">
              <Media tag="h1" className="py-5 mb-0 wow fadeInUp bg-black text-white d-block text-center">Galería de Videos</Media>
              <Col xs={12} ms={10} md={8} lg={6} className="mx-auto">
                <BannerCarousel id={"galery"} items={videos} color={color} />
              </Col>
            </div>
          )}
        {/* </LazyLoad> */}
        {/* <LazyLoad once offset={30}> */}
          <Container>
            <Media tag="h1" className="py-5 mt-4 mb-0 wow fadeInUp font-weight-bold d-block text-center">Especificaciones</Media>
            {engine && <DataSpecs description={engine} itemName="engine" title="MOTOR" />}
            {chassis && <DataSpecs description={chassis} itemName="chassis" title="CHASIS" />}
            {dimension && (dimension.height_exluding_mirrors
            || dimension.seat_height || dimension.lenght) && <DataSpecs item={dimension} itemName="dimension" title="DIMENSIONES" />}
            <Media tag="small" className="font-italic mt-5">{note}</Media>
          </Container>
        {/* </LazyLoad> */}
        {/* <LazyLoad once offset={30}> */}
          <div className="mt-5 about" data-color="about">
            <Container>
              <Media tag="h3" className="py-3 bg-app-dark mb-0 wow bounceInLeft mt-3 mt-md-0 text-center justify-content-center flex-column align-items-center">
                <strong className="mr-2 mb-2">HOLA!</strong>
                {' HACE TU CONSULTA POR ESTE PRODUCTO'}
              </Media>
              <ProductForm brand={brandName} productName={name}/>
            </Container>
          </div>
        {/* </LazyLoad> */}
      </div>
    );
  }
}

Product.propTypes = {
  brandName: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  maxTorque: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pictureColors: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  productFeatures: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  documents: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pictures: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  videos: PropTypes.array.isRequired,
  engine: PropTypes.string.isRequired,
  chassis: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dimension: PropTypes.object.isRequired,
  accessories: PropTypes.array.isRequired,
};

export default Product;
