/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink, Media,
} from 'reactstrap';
import arrow from 'images/arrow_transparent.svg';

const SendButton = ({
  text, color, link, className, target,
}) => (
  <NavLink className={`enter align-items-center mx-md-0 mx-sm-2 ${className}`} href={link} target={target} style={{ backgroundColor: color }}>
    <div className="square">
      <Media src={arrow} className="w-100" alt="Agrobikes" width="100" height="100" />
    </div>
    <Media tag="h4" className="pt-0 my-0 mx-0 align-items-center justify-content-center h-100 text-uppercase font-weight-black">{text}</Media>
  </NavLink>
);

SendButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
};

SendButton.defaultProps = {
  className: '',
  target: '',
};

export default SendButton;
