import React from 'react';
import {
  Media,
} from 'reactstrap';
// eslint-disable-next-line import/no-unresolved
import arrowW from 'images/arrow-w.svg';
import { Link } from 'react-scroll';
import LazyLoad from 'react-lazyload';

const ScrollTop = () => (
  <LazyLoad once height={40} offset={170}>
    <Link
      className="scroll-top mb-3"
      to="header"
      spy
      smooth
      offset={-70}
      duration={500}
    >
      <Media className="" src={arrowW} alt="Agrobikes" width="100" height="100" />
    </Link>
  </LazyLoad>
);

export default ScrollTop;
