import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Media, NavLink } from 'reactstrap';

class ProductCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {items, brand, slidesToShow, className} = this.props;
    const settings = {
      dots: false,
      infinite: true,
      lazyLoad: true,
      speed: 500,
      slidesToShow: slidesToShow || (items.length > 3 ? 4 : items.length),
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: slidesToShow || (items.length > 2 ? 3 : items.length),
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: slidesToShow || (items.length > 2 ? 2 : items.length),
          },
        },
      ],
    };

    return (
      <Slider {...settings} className={`${className}`}>
        {items && items.map((item) => (
          <NavLink href={brand && `${brand}/${item.slug}`} key={item} className="px-0 py-0">
            <Media object src={item.image_url || item.sm || ''} alt={item.slug} className="w-100" width="100"  />
          </NavLink>
        ))}
      </Slider>
    )
  }
}
ProductCarousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
  brand: PropTypes.string.isRequired,
  slidesToShow: PropTypes.number,
  className: PropTypes.string,
};

ProductCarousel.defaultProps = {
  slidesToShow: undefined,
  className: "",
}

export default ProductCarousel;
