import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Media, NavLink } from 'reactstrap';
import ProductCarousel from './ProductCarousel';
import wp from 'images/whatsapp.svg';
import createMarkup from './Utils';

const itemsPerPage = 4;
let arrayForHoldingItems = [];

const Accessories = (items) => {
  const [itemsToShow, setItemsToShow] = useState([]);
  const [next, setNext] = useState(6);
  const [readMore, setReadMore] = useState(true);
  const currentItems = items.items;

  const loopWithSlice = (start, end) => {
    arrayForHoldingItems = [...arrayForHoldingItems, ...currentItems.slice(start,end)];
    setItemsToShow(arrayForHoldingItems);
  };

  useEffect(() => {
    loopWithSlice(0, itemsPerPage);
  }, []);

  const handleShowMoreItems = () => {
    loopWithSlice(next, next + itemsPerPage);
    setNext(next + itemsPerPage);
    setReadMore(next + itemsPerPage < currentItems.length);
  };

  return (
    <div className="gallery bg-white text-center pb-5">
      <Row className="accessory-list mx-0 justify-content-center">
        {itemsToShow.map((accessory) => (
          <Col xs={12} sm={6} md={6} lg={4} xl={3} key={accessory.title} className="accessory-item bg-accessory d-flex flex-column justify-content-between px-0 pb-3">
            <ProductCarousel items={accessory.pictures} slidesToShow={1} className={'my-0'} brand=""/>
            <div className="h-100 bg-accessory">
              <Media tag="h5" className="text-uppercase font-weight-black mt-2 mb-1 px-4 pt-2" style={{height: '50px'}}>{accessory.title}</Media>
              {accessory.subtitle &&
                <Media tag="h5" className="text-uppercase font-weight-black text-gray mb-2 px-4 pb-3 ">{accessory.subtitle}</Media>
              }
              <div dangerouslySetInnerHTML={createMarkup(accessory.description)} className="text-left description-html px-4 pt-3 pb-4" />
            </div>
            <div className="d-flex align-items-end" style={{height: '70px'}}>              
              <Row className="mx-0 w-100">
                <Col xs={6} sm={6} className="text-center ">
                  {accessory.show_stock &&
                    <div className="d-inline-flex align-items-center text-white w-100 btn-accessory">
                      <NavLink href={accessory.link} target='_blank' className='h-100 w-75 px-2 py-2 font-weight-bold text-blue-ml bg-yellow-ml justify-content-center d-flex align-items-center'>EN STOCK</NavLink>
                      <div className=" px-1 py-1 content-ml h-100">
                        <Media src="/assets/mercado-libre.png" className="mr-2 w-100" alt="Agrobikes" width="100" height="100" />
                      </div>
                    </div>
                  }
                </Col>
                <Col xs={6} sm={6} className="text-center ">
                  <div className="d-inline-flex align-items-center text-white w-100 btn-accessory">
                    <NavLink href="https://api.whatsapp.com/send?phone=5491133115318" target='_blank' className='h-100 w-75 px-2 py-2 font-weight-bold bg-green-wp justify-content-center d-flex align-items-center'>CONSULTA</NavLink>
                    <div className=" px-1 py-1 content-wp h-100">
                      <NavLink className="px-0 py-0 w-100" href="https://api.whatsapp.com/send?phone=5491133115318" target="_blank" rel="noopener">
                          <Media src={wp} alt="Agrobikes" className="menu-img" width="100" height="100" />
                      </NavLink>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
        </Col>
        ))}
    </Row>
    <Button onClick={handleShowMoreItems} className={`mt-5 px-5 bg-white text-black font-weight-bold read-more-light ${(readMore) ? 'd-inline-block' : 'd-none'}`}>ver más accesorios</Button>
    </div>
  );
};

Accessories.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
};

export default Accessories;
