/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Media, Container,
} from 'reactstrap';
import WOW from 'wowjs';
import LazyLoad from 'react-lazyload';
import about from 'images/about.jpg';
import service from 'images/service.jpg';
import insurance from 'images/insurance.jpg';
// import accessory from 'images/accessory.jpeg';
import accessory from 'images/accessory_new.jpg';
import SendButton from './SendButton';
import createMarkup from './Utils';
import SeparatorH from './SeparatorH';

class Info extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  imgSwitch(section) {
    switch (section) {
      case 'about':
        return about;
      case 'service':
        return service;
      case 'insurance':
        return insurance;
      case 'accessory':
        return accessory;
      default:
        return '';
    }
  }

  render() {
    const {
      title, text, textLink, link, color, section, target,
    } = this.props;

    return (
      // <LazyLoad once offset={30}>
        <div className={`min-vh-50 ${section}`} data-color={section} style={{ backgroundImage: `url(${this.imgSwitch(section)})` }}>
          <Container>
            <Row className="my-4">
              <Col xs={12} sm={12}>
                <Media tag="h2" className="mb-0 wow bounceInLeft">
                  <span dangerouslySetInnerHTML={createMarkup(title)} className="description-html" />
                </Media>
                <SeparatorH color={color} className="my-5" />
                <Media tag="p" className="wow bounceInRight">
                  <span dangerouslySetInnerHTML={createMarkup(text)} className="description-html" />
                </Media>
              </Col>
              {link
                && (
                <Col xs={12} sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
                  <SendButton text={textLink} color={color} link={link} target={target} className="wow bounceInLeft mt-5" />
                </Col>
                )}
            </Row>
          </Container>
        </div>
      // </LazyLoad>
    );
  }
}

Info.propTypes = {
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textLink: PropTypes.string,
  link: PropTypes.string,
  color: PropTypes.string,
  target: PropTypes.string,
};

Info.defaultProps = {
  color: '',
  textLink: '',
  link: '',
  target: '',
};

export default Info;
