import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const CustomSelect = (props) => {
  const {
    defaultValue, values, color, selectVal,
  } = props;
  const options = values.map((value) => (
    { value: value.value, label: value.key }
  ));
  const colourStyles = {
    menu: (provided, state) => ({
      ...provided,
      border: '0px',
      marginTop: 1,
      borderColor: state.isFocused ? color : color,
      outlineColor: state.isFocused ? color : color,
      textAlign: 'center',
      color: '#ffffff',
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: color,
      border: '0',
      textAlign: 'center',
      color: 'white',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: color,
      borderColor: color,
      border: 0,
      borderRadius: 0,
      textAlign: 'center',
      color: '#ffffff',
      boxShadow: `0 0 0 0px ${color}`,
      ':active': {
        ...styles[':active'],
        borderColor: color,
        boxShadow: `0 0 0 0px ${color}`,
      },
      ':hover': {
        ...styles[':hover'],
        borderColor: color,
        boxShadow: `0 0 0 0px ${color}`,
        cursor: 'pointer',
      },
      ':focus': {
        ...styles[':focus'],
        borderColor: color,
        boxShadow: `0 0 0 0px ${color}`,
      },
    }),
    option: (styles, { isDisabled, isSelected }) => ({
      ...styles,
      backgroundColor: color,
      color: 'white',
      cursor: isDisabled ? 'not-allowed' : 'default',
      margin: 0,
      textTransform: 'uppercase',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? color : 'gray'),
        color: 'white',
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: !isDisabled && (isSelected ? color : 'gray'),
        color: 'white',
        cursor: 'pointer',
      },
    }),
    singleValue: (provided) => ({
      ...provided, color: 'white', textAlign: 'center', justifyContent: 'center', textTransform: 'uppercase', fontSize: "24.5px", padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided, color: 'white', textAlign: 'center', justifyContent: 'center', textTransform: 'uppercase', fontSize: "24.5px", padding: 0,
    }),
    placeholder: (provided) => ({
      ...provided, color: 'white', textAlign: 'center', justifyContent: 'center', fontSize: "24.5px", padding: 0,
    }),
    indicatorSeparator: () => ({}),
  };
  const dropdownIndicatorStyles = (base) => {
    const changes = {
      backgroundColor: 'white',
      padding: 16,
      color,
    };
    return Object.assign(base, changes);
  };

  return (
    <Select onChange={selectVal} options={options} styles={{ ...colourStyles, dropdownIndicator: dropdownIndicatorStyles }} placeholder="SELECT" defaultValue={defaultValue} />
  );
};

CustomSelect.propTypes = {
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.object.isRequired,
  selectVal: PropTypes.func.isRequired,
};

export default CustomSelect;
