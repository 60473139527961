import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Gallery from 'react-photo-gallery';

const itemsPerPage = 8;
let arrayForHoldingItems = [];

const GalleryImages = (items) => {
  const [itemsToShow, setItemsToShow] = useState([]);
  const [next, setNext] = useState(8);
  const [readMore, setReadMore] = useState(true);
  const currentItems = items.items;

  const loopWithSlice = (start, end) => {
    const slicedItems = getItems(currentItems, start, end);
    arrayForHoldingItems = [...arrayForHoldingItems, ...slicedItems];
    setItemsToShow(arrayForHoldingItems);
  };

  useEffect(() => {
    loopWithSlice(0, itemsPerPage);
  }, []);

  const handleShowMoreItems = () => {
    loopWithSlice(next, next + itemsPerPage);
    setNext(next + itemsPerPage);
    setReadMore(next + itemsPerPage < currentItems.length);
  };

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div className="gallery bg-black text-center pb-5">
      <Gallery photos={itemsToShow} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={itemsToShow.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Button onClick={handleShowMoreItems} className={`mt-5 px-5 bg-black text-white font-weight-bold read-more ${(readMore) ? 'd-inline-block' : 'd-none'}`}>ver más</Button>
    </div>
  );
};

GalleryImages.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array.isRequired,
};

export default GalleryImages;

function getItems(items, start, end) {
  const finish = (end < items.length) ? end : items.length;
  let arr = [];
  for (let step = start; step < finish; step++) {
    arr.push({ src: items[step].image_url, width: 4, height: 3 });
  }
  return arr;
}
