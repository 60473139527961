import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';
// eslint-disable-next-line import/no-unresolved
import playImg from 'images/play-video.svg';
import { Media } from 'reactstrap';

const Player = ({
  youtubeId, color, playing, onPlay, id
}) => (
  <div className="responsive-video responsive-video-slider" id={id}>
    <ReactPlayer
      url={`//www.youtube.com/embed/${youtubeId}`}
      width="100%"
      height="100%"
      controls
      playing={(playing === youtubeId)}
      loop
      muted
      id={id}
      config={{
        youtube: {
          playerVars: {
            showinfo: 0,
            rel: 0,
            modestbranding: 0,
            fs: 1,
            cc_load_policty: 0,
            iv_load_policy: 3,
            autohide: 0,
            playlist: youtubeId,
            origin: window.location.href,
            enablejsapi: 1,
          },
        },
      }}
      style={{ opacity: playing ? 1 : 0 }}
    />
    <PlayButton color={color} playing={playing} onPlay={onPlay} />
  </div>
);

const PlayButton = ({ color, onPlay, playing }) => {
  const btnPlay = (youtubeId) => {
    onPlay(youtubeId);
  };
  return (
    <div className={`caption-button d-flex flex-column h-100 justify-content-center align-items-center ${playing ? 'hide' : ''}`}>
      <button type="button" className="img-color-container d-flex justify-content-center btn-slider" onClick={() => btnPlay(playing)}>
        <Media className={`${playing ? 'hide' : ''}`} src={playImg} alt="Agrobikes" style={{ backgroundColor: color }} width="100" height="100" />
      </button>
    </div>
  );
};

Player.propTypes = {
  color: PropTypes.string.isRequired,
  youtubeId: PropTypes.string.isRequired,
  playing: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onPlay: PropTypes.func,
};

Player.defaultProps = {
  playing: '',
  onPlay: undefined,
};

PlayButton.propTypes = {
  color: PropTypes.string.isRequired,
  playing: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  onPlay: PropTypes.func,
};

PlayButton.defaultProps = {
  onPlay: undefined,
  playing: PropTypes.string,
};

export default Player;
