import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  FormFeedback, FormGroup, Input, Media, Row,
} from 'reactstrap';
import * as yup from 'yup';
import { Field, Formik, Form } from 'formik';
// eslint-disable-next-line import/no-unresolved
import arrow from 'images/arrow_transparent.svg';
import { postContact } from '../utils/Api';
import CustomSelect from './CustomSelect';

const customInputForm = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Input
      invalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && <FormFeedback>{errors[field.name]}</FormFeedback>}
  </div>
);

const SignupSchema = yup.object().shape({
  name: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .max(50, 'Debe contener menos de 50 caracteres.')
    .required('Requerido *'),
  message: yup.string()
    .min(10, 'Debe contener más de 10 caracteres.')
    .max(250, 'Debe contener menos de 250 caracteres.')
    .required('Requerido *'),
  email: yup.string()
    .email('Correo electrónico inválido.')
    .required('Requerido *'),
  city: yup.string()
    .min(2, 'Debe contener más de 2 caracteres.')
    .required('Requerido *'),
});

var id ="";

class CustomForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.selectVal = this.selectVal.bind(this);
    this.state = {
      msg: '',
      isSubmitting: false,
      selectVal: {},
    };
  }

  componentDidMount() {
    if(this.props.emails){
      this.state.selectVal = { value: this.props.emails[0].value, label: this.props.emails[0].key };
    }
  }

  setSubmitting(isSubmitting) {
    this.setState({ isSubmitting });
  }

  selectVal(val) {
    this.setState({ selectVal: val });
    switch (val.label) {
      case "informacion":
        id = "consulta_info";
        break;
      case "ventas":
        id = "consulta_ventas";
        break;
      case "recurso humano":
        id = "consulta_recursohumano";
        break;
      default:
        id = "";
    }
  }

  render() {
    const {
      emails, color, identifier
    } = this.props;

    return (
      <Formik
        initialValues={{
          email: '',
          name: '',
          message: '',
          city: '',
          phone: '',
          section_page: '',
          section: '',
          age: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          values.section_page = window.location.pathname;
          values.section = this.state.selectVal.value;
          // actions.setSubmitting(false);
          this.setSubmitting(false);
          postContact(values).then((response) => {
            this.setSubmitting(true);
            if (typeof response.data === 'string') {
              this.setState({ msg: response.data });
            } else {
              this.setState({ msg: 'Su mensaje a sido enviado exitosamente!!' });
            }
            actions.resetForm({ values: '' });
          }).catch((error) => {
            this.setState({ msg: error.data });
          });
        }}
      >
        {(props) => (
          <span>
            {this.state.msg && (
              // <Media tag="h6" className="alert alert-success mt-2" id={emails[0].value}>
              //   {this.state.msg}
              // </Media>
              <Media tag="h6" className="alert alert-success mt-2" id={id || identifier[0].value}>
                {this.state.msg}
              </Media>
            )}
            <Form className="mt-5" onSubmit={props.handleSubmit} action="/contact">
              <FormGroup className="mb-5">
                <CustomSelect
                  defaultValue={{ value: emails[0].value, label: emails[0].key }}
                  selectVal={this.selectVal}
                  values={emails}
                  color={color}
                  component={customInputForm}
                />
              </FormGroup>
              <FormGroup>
                <Field name="name" type="text" component={customInputForm} placeholder="NOMBRE *" required className="input-form" />
              </FormGroup>
              <FormGroup>
                <Field type="email" name="email" component={customInputForm} placeholder="CORREO ELECTRONICO *" required className="input-form" />
              </FormGroup>
              <FormGroup>
                <Field type="text" name="phone" component={customInputForm} placeholder="NUMERO DE TELEFONO" className="input-form" />
              </FormGroup>
              <FormGroup>
                <Field type="text" name="city" component={customInputForm} placeholder="CIUDAD/PROVINCIA *" required className="input-form" />
              </FormGroup>
              <FormGroup>
                <Field type="textarea" name="message" component={customInputForm} placeholder="MENSAJE *" required className="input-form" />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs={{ size: 6, offset: 6 }}>
                    <Button
                      className="enter align-items-center mt-5 w-100 border-0 "
                      style={{ backgroundColor: color }}
                    >
                      <div className="square">
                        <Media src={arrow} className="w-100" alt="Agrobikes" width="100" height="100" />
                      </div>
                      <Media tag="h3" className="pt-0 my-0 mx-0 align-items-center justify-content-center h-100">ENVIAR</Media>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </span>
        )}
      </Formik>
    );
  }
}

CustomForm.propTypes = {
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  emails: PropTypes.array,
};

CustomForm.defaultProps = {
  emails: [],
};

export default CustomForm;
