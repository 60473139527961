import React from 'react';
import PropTypes from 'prop-types';

const SeparatorH = ({ color, color2, className }) => (
  <span className={`separator ${className}`}>
    <span className="line-one" style={{ backgroundColor: color }} />
    <span className="line-two" style={{ backgroundColor: color2 }} />
  </span>
);

SeparatorH.propTypes = {
  color: PropTypes.string.isRequired,
  color2: PropTypes.string,
  className: PropTypes.string,
};

SeparatorH.defaultProps = {
  color2: '',
  className: '',
};

export default SeparatorH;
