import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Media, NavLink } from 'reactstrap';
import {
  Col, Container, Row,
} from 'reactstrap';
import img_suzuki from 'images/suzuki_logo_blanco.svg';
import Slider from 'react-slick';
import SendButton from './SendButton';

class BrandContainerNew extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      imagenes: []
    };
  }

  componentDidMount() {
    fetch("https://www.suzukiagronomia.com.ar/products.json")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            imagenes: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
 
  render() {
    const {items, slidesToShow} = this.props;
    const {error, isLoaded, imagenes } = this.state;
    const url = "https://www.suzukiagronomia.com.ar/" 
    const settings = {
      dots: false,
      infinite: true,
      lazyLoad: true,
      speed: 500,
      slidesToShow: slidesToShow || (imagenes.length > 3 ? 4 : imagenes.length),
      slidesToScroll: 1,
      autoplay: true,
      pauseOnHover: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: slidesToShow || (imagenes.length > 2 ? 3 : imagenes.length),
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: slidesToShow || (imagenes.length > 2 ? 2 : imagenes.length),
          },
        },
      ],
    };
    
    return (
      <Fragment>
        <div>
          <div className='container'>
            <div className="my-4 my-md-5 min-vh-50 row">
              <div className="col-12 order-2 col-md-6 order-md-1">
                <div className="description-html mt-4 wow fadeInUp">
                  <h2>
                    <strong>SUZUKI </strong>AGRONOMIA
                  </h2>
                </div>
                <div>
                  <span className="separator my-5">
                    <span className="line-one" style={{backgroundColor: "rgb(18, 56, 133)"}}></span>
                    <span className="line-two"></span>
                  </span>
                  <p className="media">
                    <span className="description-html">
                      <p>Suzuki Motos ofrece a sus clientes un producto de alta calidad y valoración que inspira lealtad entre sus usuarios, satisfaciendo sus necesidades y simplificando sus vidas. Suzuki Motor Corporation es una empresa líder, de amplia trayectoria, a la vanguardia y con un alto potencial de desarrollo tecnológico. Suzuki basa su filosofía de trabajo y producción en 4 pilares clave: confiabilidad, potencia, trayectoria y conveniencia.
                      </p>
                    </span>
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-md-center my-4 col-12 order-1 col-md-6 order-md-2">
                <img class="w-75 h-50 img-logo-brand" width="100" height="100" src={img_suzuki} />
              </div>
            </div>
          </div>
          <div id="out_suzuki_slider">
          <Slider {...settings} className="my-4">
            {imagenes && imagenes.map((item) => (
              <NavLink href={item.url} key={item.id} className="px-0 py-0">
                { <Media object src={item.image || ''} alt={item.url} className="w-100" width="100"  /> }
              </NavLink>
            ))}
          </Slider>
          </div>
          <div>
            <Row className="mx-0 my-5">
                <Col
                  xs={12}
                  sm={{ size: 10, offset: 1 }}
                  md={{ size: 8, offset: 2 }}
                  lg={{ size: 6, offset: 3 }}
                  xl={{ size: 4, offset: 4 }}
                >
                  <SendButton text={"INGRESAR A SUZUKI"} color="#163885" link={url} className="mb-5" />
                </Col>
            </Row>
          </div>
        </div>
      </Fragment>
    );
  }
}

BrandContainerNew.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  //items: PropTypes.array.isRequired,
};

export default BrandContainerNew;
