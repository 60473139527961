import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Media, Container, NavLink,
} from 'reactstrap';
import WOW from 'wowjs';
import LazyLoad from 'react-lazyload';
// eslint-disable-next-line import/no-unresolved
import arrow from 'images/arrow_transparent.svg';
import CustomForm from './CustomForm';
import createMarkup from './Utils';

class InfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showForm: false };
  }

  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  render() {
    const {
      title, phoneText, phoneNum, phoneWText, phoneWNum, info, emails, color, contactWP, identifier, phoneWTwoText, phoneWTwoNum
    } = this.props;
    const { showForm } = this.state;

    return (
      // <LazyLoad once offset={50}>
        <Container>
          <Row className="py-5 min-vh-50">
            <Col xs={12}>
              <Media tag="h2" className="mb-0 wow bounceInLeft">
                <strong className="mr-2">{title}</strong>
                {' AGROBIKES'}
              </Media>
            </Col>
            <Col xs={12} md={6}>
              <NavLink href={phoneNum} className="px-0 py-0" target="_blank" rel="noopener">
                <Media tag="h2" className="mb-0 wow bounceInLeft mt-4"><b>{phoneText}</b></Media>
              </NavLink>
              {phoneWNum
                && (
                <NavLink href={phoneWNum} className="px-0 py-0" target="_blank" rel="noopener">
                  <Media tag="h2" className="mb-0 wow bounceInLeft"><b>{phoneWText}</b></Media>
                </NavLink>
                )}
              {phoneWTwoNum
                && (
                <NavLink href={phoneWTwoNum} className="px-0 py-0" target="_blank" rel="noopener">
                  <Media tag="h2" className="mb-0 wow bounceInLeft"><b>{phoneWTwoText}</b></Media>
                </NavLink>
                )}
              {contactWP
                && (
                  <div className="mt-3">
                    {contactWP.map((item) => (
                      <NavLink key={item.id} href={item.number} className="px-0 py-0" target="_blank" rel="noopener">
                        <Media tag="h2" className="mb-0 wow bounceInLeft d-inline-block"><b>{item.text}</b></Media>
                        <Media tag="p" className="ml-1 d-inline-block my-0">(Whatsapp)</Media>
                      </NavLink>
                    ))}
                  </div>
                )}
              {info && info.map((item) => (
                <div key={item.id} className="mt-4 pr-2">
                  {item.text1 && (
                  <Media tag="p" className="mb-1 wow bounceInLeft">
                    <span dangerouslySetInnerHTML={createMarkup(item.text1)} className="description-html" />
                  </Media>
                  )}
                  {item.phoneNum && (
                  <NavLink href={item.phoneNum} className="px-0 pt-0 pb-1" target="_blank" rel="noopener">
                    {item.phone}
                  </NavLink>
                  )}
                  {item.phoneNum2 && (
                  <NavLink href={item.phoneNum2} className="px-0 pt-0 pb-1" target="_blank" rel="noopener">
                    {item.phone2}
                  </NavLink>
                  )}
                  {item.text2 && (
                  <Media tag="p" className="mb-1 wow bounceInLeft">
                    <span dangerouslySetInnerHTML={createMarkup(item.text2)} className="description-html" />
                  </Media>
                  )}
                  {item.addressMap && (
                  <NavLink href={item.addressMap} className="px-0 pt-0 pb-1" target="_blank" rel="noopener">
                    <span dangerouslySetInnerHTML={createMarkup(item.address)} className="description-html" />
                  </NavLink>
                  )}
                </div>
              ))}
              {emails && (
              <div className="mt-4">
                {emails.map((item) => (
                  <NavLink href={`mailto:${item.value}`} className="px-0 pt-0 pb-1" target="_blank" key={item.id} rel="noopener">
                    {item.value}
                  </NavLink>
                ))}
              </div>
              )}
              <div className="mt-4">
                <NavLink href={`mailto:info@agrobikes.com.ar`} className="px-0 pt-0 pb-1">
                  ¿Querés ser parte de nuestro equipo de Agrobikes?
                </NavLink>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <Media tag="h2" className="mb-0 mt-4 wow bounceInLeft d-none d-md-block text-center">
                <strong className="mr-2">HOLA!</strong>
                {' REALIZA TU CONSULTA'}
              </Media>
              <NavLink
                className="enter align-items-center mt-5 w-100 border-0 d-block d-md-none"
                style={{ backgroundColor: color }}
                onClick={() => this.setState({ showForm: !showForm })}
              >
                <div className="square">
                  <Media src={arrow} className="w-100" alt="Agrobikes" width="100" height="100" />
                </div>
                <Media tag="h3" className="pt-0 my-0 mx-0 align-items-center justify-content-center h-100">
                  <strong className="pt-0 mr-2">HOLA!</strong>
                  {' REALIZA TU CONSULTA'}
                </Media>
              </NavLink>
              <div className={`${showForm ? 'd-block' : 'd-none d-md-block'} `}>
                <CustomForm emails={emails} color={color} identifier={identifier} />
              </div>
            </Col>
          </Row>
        </Container>
      // </LazyLoad>
    );
  }
}

InfoForm.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  emails: PropTypes.array,
  phoneText: PropTypes.string,
  phoneNum: PropTypes.string,
  phoneWText: PropTypes.string,
  phoneWNum: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  contactWP: PropTypes.array,
};

InfoForm.defaultProps = {
  phoneWText: '',
  phoneWNum: '',
  phoneText: '',
  phoneNum: '',
  info: [],
  emails: [],
  contactWP: undefined,
};

export default InfoForm;
