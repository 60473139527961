import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Media,
} from 'reactstrap';
import WOW from 'wowjs';
// eslint-disable-next-line import/no-unresolved
import logoAgrobikes from 'images/agrobikes.svg';
import createMarkup from './Utils';
import SeparatorH from './SeparatorH';

class DataBrand extends Component {
  componentDidMount() {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }

  render() {
    const {
      title, color, color2, description, logo, name,
    } = this.props;
    return (
      <Row className="my-4 my-md-5 min-vh-50">
        <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
          <div dangerouslySetInnerHTML={createMarkup(title)} className="description-html mt-4 wow fadeInUp" />
          <SeparatorH color={color} color2={color2} className="my-5" />
          <Media tag="p">
            <span dangerouslySetInnerHTML={createMarkup(description)} className="description-html" />
          </Media>
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }} className="d-flex align-items-center justify-content-md-center my-4">
          <Media object src={logo} alt={name} className="w-75 h-50 img-logo-brand" width="100" height="100" />
        </Col>
      </Row>
    );
  }
}

DataBrand.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  color2: PropTypes.string,
};

DataBrand.defaultProps = {
  color: '',
  color2: '',
  logo: logoAgrobikes,
  name: 'Agrobikes',
};

export default DataBrand;
